import * as THREE from "three";

const customProperties = {
	scene_1_frame_1_yak: {
		renderOrder: 100,
	},
	scene_1_frame_1_yak_blur: {
		renderOrder: 200,
	},
	scene_1_frame_1_yak_trace: {
		renderOrder: 300,
	},
	scene_1_frame_1_yak_fire: {
		renderOrder: 400,
	},
	scene_1_frame_1_yak_fire: {
		renderOrder: 400,
	},
	scene_1_frame_1_yak_trace: {
		material: {
			alphaMap: null,
		},
	},
	scene_1_frame_2_yak: {
		renderOrder: 100,
	},
	scene_1_frame_2_yak_blur: {
		renderOrder: 200,
	},
	scene_1_frame_2_yak_trace: {
		renderOrder: 300,
	},
	scene_1_frame_2_yak_fire: {
		renderOrder: 400,
	},
	scene_1_frame_2_yak_smoke_particles: {
		renderOrder: 500,
	},
	scene_1_frame_2_yak_propeller: {
		renderOrder: 600,
	},
	scene_2_frame_1_snow: {
		renderOrder: 100,
	},
	scene_2_frame_1_yak: {
		renderOrder: 200,
	},
	scene_2_frame_1_yak_wheel: {
		renderOrder: 300,
	},
	scene_2_frame_1_dirt: {
		renderOrder: 400,
	},
	scene_2_frame_1_legs: {
		renderOrder: 500,
		material: {
			side: THREE.FrontSide
		}
	},
	scene_2_frame_1_arm: {
		renderOrder: 600,
	},
};

export function applyCustomProperties(scene, keyInclusive) {
	Object.keys(customProperties).forEach((key) => {
		if (keyInclusive && !key.includes(keyInclusive)) return;
		const object = scene.getObjectByName(key);
		if (!object) return;
		propertiesRecursive(object, customProperties[key]);
	});
}

function propertiesRecursive(obj, customProps) {
	Object.keys(customProps).forEach((key) => {
		if (key == "material") {
			propertiesRecursive(obj.material, customProps.material);
			return;
		}
		if (key == "parent") {
			const parent = obj.parent.getObjectByName(customProps[key]);
			parent.attach(obj);
			return;
		}

		obj[key] = customProps[key];
	});
}
