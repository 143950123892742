export const servObj = window.servObj = {};

servObj.onHover3D = [];
servObj.onClick3D = [];
servObj.isJumping = false;
servObj.currentFrame = undefined;

servObj.currentScene = 1;
servObj.startScene = 1;
servObj.finalScene = 2;
servObj.gameIsStarted = false;