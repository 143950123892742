const elements = document.querySelectorAll(".annotation-3d");
const annotations3D = [];

export const annotations = {
	elements: annotations3D,
	init: init,
	update: update,
	setAnnVisibility: setAnnVisibility,
	setTextVisibility: setTextVisibility,
}

function init(scene) {
	for (const el of elements) {
		const annID = el.id.split("ann_")[1]; //
		const object = scene.getObjectByName(annID);
		if (!object) return;
		annotations3D.push({
			element: el,
			position: object.position,
		});
	}
}

function update(camera) {
	for (const ann of annotations3D) {
		const screenPosition = ann.position.clone();
		screenPosition.project(camera);

		const translateX = screenPosition.x * container3D.offsetWidth * 0.5;
		const translateY = -screenPosition.y * container3D.offsetHeight * 0.5;
		ann.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`;
	}
}

function setAnnVisibility(objectName, visible) {
	const el = document.querySelector("#ann_" + objectName);
	if (!el) return;
	visible ? el.classList.add("visible") : el.classList.remove("visible");
}

function setTextVisibility(objectName, visible) {
	const el = document.querySelector("#ann_" + objectName);
	if (!el) return;
	const children = [...el.children];
	const annText = children.find((child) => child.className.includes("ann-text"));
	if (!annText) return;
	visible ? annText.classList.add("visible") : annText.classList.remove("visible");
}
