import { loader } from "./loader";
import { materials } from "./materials";
import { applyCustomProperties } from "./custom_properties.js";
import * as Camera from "./Camera.js";
import { animations } from "./animations.js";
import { setVisibility } from "./visibility_recursive.js";
import gsap from "gsap";

const texturesToLoad = {
	scene_1_frame_1: {
		path: "./scene_1_textures/frame_1_textures",
		isLoaded: false,
		textures: [
			"scene_1_frame_1_background.webp",
			"scene_1_frame_1_clouds.webp",
			"scene_1_frame_1_forest.webp",
			"scene_1_frame_1_gradient_1.webp",
			"scene_1_frame_1_gradient_2.webp",
			"scene_1_frame_1_messer_1.webp",
			"scene_1_frame_1_messer_1_blur.webp",
			"scene_1_frame_1_messer_1_trace.webp",
			"scene_1_frame_1_messer_2.webp",
			"scene_1_frame_1_messer_2_blur.webp",
			"scene_1_frame_1_messer_2_trace.webp",
			"scene_1_frame_1_messer_3.webp",
			"scene_1_frame_1_messer_3_trace.webp",
			"scene_1_frame_1_progress_alpha.webp",
			"scene_1_frame_1_smokes.webp",
			"scene_1_frame_1_yak.webp",
			"scene_1_frame_1_yak_blur.webp",
			"scene_1_frame_1_yak_fire.webp",
			"scene_1_frame_1_yak_trace.webp",
		],
	},
	scene_1_frame_2: {
		path: "./scene_1_textures/frame_2_textures",
		isLoaded: false,
		textures: [
			"scene_1_frame_2_background.webp",
			"scene_1_frame_2_clouds.webp",
			"scene_1_frame_2_forest.webp",
			"scene_1_frame_2_snow_overlay.webp",
			"scene_1_frame_2_traces.webp",
			"scene_1_frame_2_trees_1_blur.webp",
			"scene_1_frame_2_trees_2_blur.webp",
			"scene_1_frame_2_trees_3.webp",
			"scene_1_frame_2_trees_4.webp",
			"scene_1_frame_2_yak.webp",
			"scene_1_frame_2_yak_blur.webp",
			"scene_1_frame_2_yak_fire.webp",
			"scene_1_frame_2_yak_smoke_particles.webp",
			"scene_1_frame_2_yak_trace.webp",
			"scene_1_frame_2_yak_propeller.webp",
		],
	},
	scene_2_frame_1: {
		path: "./scene_2_textures/frame_1_textures",
		isLoaded: false,
		textures: [
			"scene_2_frame_1_background.webp",
			"scene_2_frame_1_dirt.webp",
			"scene_2_frame_1_forest.webp",
			"scene_2_frame_1_ground.webp",
			"scene_2_frame_1_snow.webp",
			"scene_2_frame_1_trees_1.webp",
			"scene_2_frame_1_trees_2.webp",
			"scene_2_frame_1_yak.webp",
			"scene_2_frame_1_yak_wheel.webp",
			
			"scene_2_frame_1_arm.webp",
			"scene_2_frame_1_legs.png",
		],
	},
};

export const scenes = {
	init: init,
	initScene: initScene,
	jumpToScene: jumpToScene,
	setBtnsState,

	scene_1: {
		resolveOnReady: undefined,
	},
	scene_1_frame_1: {
		init: scene1Frame1Init,
		camera: undefined,
		play: undefined,
		stop: undefined,
		paused: undefined,
	},
	scene_1_frame_2: {
		init: scene1Frame2Init,
		camera: undefined,
		play: undefined,
		stop: undefined,
		paused: undefined,
	},
	scene_2: {
		resolveOnReady: undefined,
	},
	scene_2_frame_1: {
		init: scene2Frame1Init,
		camera: undefined,
		play: undefined,
		stop: undefined,
		paused: undefined,
	},
};

servObj.currentFrame = undefined;
let scene, sceneLoadPromise;
function init(incomingScene, promise) {
	scene = incomingScene;
	sceneLoadPromise = promise;
}

async function initScene(sceneName) {
	const sceneFrameKeys = Object.keys(scenes).filter((key) => key.includes(sceneName + "_"));
	if (!sceneFrameKeys) return;
	let loadedCount = 0;
	sceneFrameKeys.forEach((key) => {
		if (!key.includes(sceneName + "_")) return;

		loader.loadTextures(
			texturesToLoad[key].path,
			texturesToLoad[key].textures,
			async (textures) => {
				texturesToLoad[key].isLoaded = true;
				loadedCount++;
				const nextSceneNum = Number(sceneName.split("_")[1]) + 1;
				if (loadedCount === sceneFrameKeys.length) initScene("scene_" + nextSceneNum);

				if (sceneLoadPromise) await sceneLoadPromise;

				textures.forEach((texture) => {
					let material = materials.list.find((mat) => mat.name === texture.name.split(".")[0]);
					if (!material) return;
					material.map = texture;
					material.needsUpdate = true;
				});

				scenes[key].init();
				applyCustomProperties(scene, key);
				if (scenes[sceneName].resolveOnReady && loadedCount === sceneFrameKeys.length)
					scenes[sceneName].resolveOnReady();
			}
		);
	});
}

let prevStepBtn = document.querySelector('.step_btn.prev_step');
let nextStepBtn = document.querySelector('.step_btn.next_step');
function setBtnsState(){
	prevStepBtn && prevStepBtn.classList[servObj.currentScene <= servObj.startScene ? 'add' : 'remove']('disabled');
	nextStepBtn && nextStepBtn.classList[servObj.currentScene >= servObj.finalScene ? 'add' : 'remove']('disabled');
}

[prevStepBtn, nextStepBtn].forEach(el=>{
    if(!el) return;
    el.addEventListener('pointerdown', e=>{
        servObj.currentScene += el === prevStepBtn ? -1 : 1;
        scenes.jumpToScene(servObj.currentScene, 1, null, false);
    });
    
});

function jumpToScene(sceneNum, frameNum = 1, onComplete, autoTransition = true) {
	if (servObj.isJumping) return;
	servObj.isJumping = true;
	document.body.style.pointerEvents = 'none';
	if(autoTransition && sceneNum != servObj.currentScene) servObj.currentScene = sceneNum;
	animations.fadeOut({
		onComplete: () => {
			if (scenes[servObj.currentFrame].stop) scenes[servObj.currentFrame].stop();
			scenes["scene_" + sceneNum + "_frame_" + frameNum].play();
			if (onComplete) onComplete();
			document.body.style.pointerEvents = '';
			servObj.isJumping = false;
			setBtnsState();
		},
	});
}

function showFrame(frameName) {
	scene.traverse((obj) => {
		if (!obj.isMesh) return;
		if (obj.name.includes(frameName)) setVisibility(obj, true);
		else setVisibility(obj, false);
	});
}

function setOverlaysVisibility(className, visible) {
	const overlays = document.querySelector(className);
	overlays.style.display = visible ? "block" : "none";
}

function initFrameCamera(frameName) {
	const importedCamera = scene.getObjectByName(frameName + "_camera");
	const importedCameraTarget = scene.getObjectByName(frameName + "_camera_target");

	const camera = (scenes[frameName].camera = new Camera.Perspective(
		container3D.canvas,
		importedCamera.fov,
		importedCamera.aspect
	));
	camera.name = importedCamera.name;
	camera.setRotateOnPointerMove(true);
	camera.controls.setLookAt(
		importedCamera.position.x,
		importedCamera.position.y,
		importedCamera.position.z,
		importedCameraTarget.position.x,
		importedCameraTarget.position.y,
		importedCameraTarget.position.z
	);
	camera.controls.dollySpeed = 0;
	camera.controls.truckSpeed = 0;
	document.addEventListener("keypress", (event) => {
		if (event.key === "c") {
			console.log(camera.name, camera.controls.polarAngle, camera.controls.azimuthAngle);
		}
	});
	return camera;
}

function scene1Frame1Init() {
	const camera = initFrameCamera("scene_1_frame_1");
	camera.controls.minPolarAngle = 1.5;
	camera.controls.maxPolarAngle = 1.6;
	camera.controls.minAzimuthAngle = -0.06;
	camera.controls.maxAzimuthAngle = 0.06;

	let gsapTL = gsap.timeline();
	scenes["scene_1_frame_1"].play = () => {
		servObj.currentFrame = "scene_1_frame_1";
		scenes["scene_1_frame_1"].stop();
		scenes["scene_1_frame_1"].paused(false);
		showFrame("scene_1_frame_1");
		setOverlaysVisibility(".scene-1-frame-1-overlays", true);
		scene.setActiveCamera(camera);
		animations.fadeIn();

		const onAnimComplete = () => 
			jumpToScene(1, 2, () => setOverlaysVisibility(".scene-1-frame-1-overlays", false));

		animations.importedAnimPlay({
			clipNameIncludes: "scene_1_frame_1",
			callbackOfssetS: -3,
			onComplete: onAnimComplete,
		});

		gsapTL.kill();
		//...
	};
	scenes["scene_1_frame_1"].paused = (paused) => {
		animations.importedAnimPaused("scene_1_frame_1", paused);
		gsapTL.paused(paused);
	};
	scenes["scene_1_frame_1"].stop = () => {
		animations.importedAnimStop("scene_1_frame_1");
		setOverlaysVisibility(".scene-1-frame-1-overlays", false);
		gsapTL.kill();
	};
}

function scene1Frame2Init() {
	const camera = initFrameCamera("scene_1_frame_2");
	camera.controls.minPolarAngle = 1.525;
	camera.controls.maxPolarAngle = 1.575;
	camera.controls.minAzimuthAngle = -0.05;
	camera.controls.maxAzimuthAngle = 0.05;

	let gsapTL = gsap.timeline();
	scenes["scene_1_frame_2"].play = () => {
		servObj.currentFrame = "scene_1_frame_2";
		showFrame("scene_1_frame_2");
		scene.setActiveCamera(camera);
		animations.fadeIn();

		const onAnimComplete = () => jumpToScene(2, 1);
		gsapTL.to({}, {duration: 3, onComplete: onAnimComplete}); //TMP
	};
	scenes["scene_1_frame_2"].paused = (paused) => {
		animations.importedAnimPaused("scene_1_frame_2", paused);
		gsapTL.paused(paused);
	};
	scenes["scene_1_frame_2"].stop = () => {
		animations.importedAnimStop("scene_1_frame_2");
		gsapTL.kill();
	};
}

function scene2Frame1Init() {
	const camera = initFrameCamera("scene_2_frame_1");
	camera.setRotateOnPointerMove(true, true);
	camera.controls.minPolarAngle = 1.5;
	camera.controls.maxPolarAngle = 1.6;
	camera.controls.minAzimuthAngle = 0.03;
	camera.controls.maxAzimuthAngle = 0.142;
	camera.setViewOffset(
		container3D.canvas.offsetWidth,
		container3D.canvas.offsetHeight,
		container3D.canvas.offsetWidth * 0.2,
		container3D.canvas.offsetHeight * 0.1,
		container3D.canvas.offsetWidth * 0.8,
		container3D.canvas.offsetHeight * 0.8
	);

	scenes["scene_2_frame_1"].play = () => {
		servObj.currentFrame = "scene_2_frame_1";
		showFrame("scene_2_frame_1");
		scene.setActiveCamera(camera);
		animations.fadeIn();
	};
}
