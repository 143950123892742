import * as THREE from "three";
import { loader } from "./loader.js";
import { onResize } from "./resize.js";
import { initRaycaster } from "./raycaster.js";
import { annotations } from "./annotations.js";
import { animations } from "./animations.js";
import { scenes } from "./scenes.js";
import * as Camera from "./Camera.js";

const container3D = document.querySelector(".d3d-container");
const foreground = document.querySelector(".foreground");

const renderer = new THREE.WebGLRenderer({
	antialias: true,
	toneMapping: THREE.ACESFilmicToneMapping,
	outputColorSpace: THREE.SRGBColorSpace,
});
const canvas = renderer.domElement;
container3D.appendChild(canvas);
renderer.setSize(container3D.offsetWidth, container3D.offsetHeight);

window.container3D = container3D;
window.container3D.foreground = foreground;
container3D.foreground.style.opacity = 1;
window.container3D.canvas = canvas;

const scene = new THREE.Scene();
let activeCamera;
scene.setActiveCamera = (camera) => {
	activeCamera = camera;
	onResize(renderer, activeCamera, container3D);
};

const firstSceneInitPromise = new Promise(function (resolve) {
		scenes["scene_1"].resolveOnReady = resolve;
	});

const sceneLoadPromise = new Promise(function (resolve, reject) {
	loader.loadGLTF("./3d/maresyev.glb", async (gltf) => {
		scene.add(gltf.scene);
		scene.animations = gltf.animations;

		console.log(scene, renderer);

		resolve();

		animations.initHandler(scene);

		await firstSceneInitPromise; 
		// scenes["scene_1_frame_1"].play();
		activeCamera = new Camera.Perspective(canvas);
		initRaycaster(scene, activeCamera);

		annotations.init(scene);

		onResize(renderer, activeCamera, container3D);
		window.addEventListener("resize", () => onResize(renderer, activeCamera, container3D));

		animate();

		let globalLoader = document.getElementById('global-loader');
		globalLoader && (globalLoader.style.display = 'none');
	});
});

scenes.init(scene, sceneLoadPromise);
scenes.initScene("scene_1");

const clock = new THREE.Clock();
function animate() {
	const deltaTime = clock.getDelta();

	requestAnimationFrame(animate);

	annotations.update(activeCamera);

	activeCamera.update(deltaTime);

	animations.update(deltaTime);

	renderer.render(scene, activeCamera);
}
