import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import * as THREE from "three";
import { RGBELoader } from "three/addons/loaders/RGBELoader.js";
import { materials } from "./materials.js";
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';

const textureLoader = new THREE.TextureLoader();
const rgbeLoader = new RGBELoader();

const gltfLoader = new GLTFLoader();
const dracoLoader = new DRACOLoader();
dracoLoader.setDecoderPath( '/draco/gltf/' );
dracoLoader.preload();
gltfLoader.setDRACOLoader( dracoLoader );

export const loader = {
	textureLoader: textureLoader,
	gltfLoader: gltfLoader,
	rgbeLoader: rgbeLoader,
	loadGLTF: loadGLTF,
	loadTextures: loadTextures,
}

function loadGLTF(path, onLoad) {
	gltfLoader.load(path, (gltf) => {
		gltf.scene.traverse((obj) => {
			if (obj.material) materials.setMaterial(obj);
		});

		if (onLoad) onLoad(gltf);
	});
}

function loadTextures(folderPath, textureNames = [], onLoad) {
	let loadedCount = 0;
	let textures = [];
	textureNames.forEach((fileName) => {
		if (fileName.includes(".hdr") || fileName.includes(".exr")) {
			rgbeLoader.load(`${folderPath}/${fileName}`, (texture) => {
				texture.name = fileName;
				texture.mapping = THREE.EquirectangularReflectionMapping;
				textures.push(texture);
				loadedCount++;
				if (onLoad && loadedCount == textureNames.length) onLoad(textures);
			});
		} else {
			textureLoader.load(`${folderPath}/${fileName}`, (texture) => {
				texture.name = fileName;
				texture.wrapS = THREE.MirroredRepeatWrapping;
				texture.wrapT = THREE.MirroredRepeatWrapping;
				texture.anisotropy = 4;
				// texture.premultiplyAlpha = true;
				texture.flipY = false;
				texture.colorSpace = THREE.SRGBColorSpace;

				textures.push(texture);
				loadedCount++;
				if (onLoad && loadedCount == textureNames.length) onLoad(textures);
			});
		}
	});
}
