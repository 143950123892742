import { scenes } from "./scenes.js";

let modals = Array.from(document.querySelectorAll('.modal'));
let modalOpeners = document.querySelectorAll('.modal_opener');
let modalClosers = document.querySelectorAll('.modal_closer');
let modalFades = document.querySelectorAll('.fade');

modalOpeners.forEach(modalOpener=>{
    modalOpener.addEventListener('pointerdown', function(e){
        let modalTarget = this.dataset.modalTarget;
        modals.forEach(modal=>{
            modal.classList[modal.id==modalTarget?'add':'remove']('show');
        });
        
        if (servObj.currentFrame) scenes[servObj.currentFrame].paused(true);
    });
});

modalClosers.forEach(modalCloser=>{
    modalCloser.addEventListener('pointerdown', function(e){
        let modalTarget = this.dataset.modalTarget;
        let modal = modals.find(modal=>modal.id==modalTarget);
        modal && modal.classList.remove('show');

        if (servObj.currentFrame) scenes[servObj.currentFrame].paused(false);
    });
});

modalFades.forEach(fade=>{
    fade.addEventListener('pointerdown', e=>{
        let modal = e.target.closest('.modal');
        modal && modal.classList.remove('show');

        if (servObj.currentFrame) scenes[servObj.currentFrame].paused(false);
    });
});