import './init_glob.js';
import { servObj } from './init_glob.js';
import './maresyev.js';
import './modals.js';
import { scenes } from "./scenes.js";
console.log('scenes: ', scenes);

let startGameBtn = document.querySelector('.start_game_btn');
startGameBtn && startGameBtn.addEventListener('pointerdown', function(e){
    
    let firstScreen = document.querySelector('.first_screen');
    if(firstScreen){
        firstScreen.style.opacity = 0;
        setTimeout(()=>firstScreen.style.display = 'none', 1000)
    }
    scenes["scene_1_frame_1"].play();
    servObj.gameIsStarted = true;
});

let logo = document.querySelector('.logo');
logo && logo.addEventListener('pointerdown', function(e){
    if(!servObj.gameIsStarted)return;
    let modalBackToStart = document.getElementById('back-to-start');
    modalBackToStart && modalBackToStart.classList.add('show');
    if (servObj.currentFrame) scenes[servObj.currentFrame].paused(true);
});

let confirmBackToStartBtn = document.querySelector('.confirm-back-to-start');
confirmBackToStartBtn && confirmBackToStartBtn.addEventListener('pointerdown', function(e){
    let firstScreen = document.querySelector('.first_screen');
    if(!firstScreen) return;
    firstScreen.style.display = 'flex';
    setTimeout(()=>firstScreen.style.opacity = 1, 10);
    servObj.currentScene = 1;
    servObj.gameIsStarted = false;
    scenes.setBtnsState();
    
    if (servObj.currentFrame) scenes[servObj.currentFrame].paused(true);
});

// let soundBtn = document.querySelector('.sound_btn');
// soundBtn && soundBtn.addEventListener('pointerdown', function(e){
//     this.classList.toggle('mute');
// });

// let stepBtns = document.querySelectorAll('.step_btn');
// stepBtns.forEach(stepBtn=>{
//     let stepType = stepBtn.dataset.stepType;
//     if(stepType == 'prev'){
//         prevStepBtn = stepBtn;
//     }else{
//         nextStepBtn = stepBtn;
//     };
//     stepBtn.addEventListener('pointerdown', e=>{
//         servObj.currentScene += stepType == 'prev' ? -1 : 1;
//         prevStepBtn && prevStepBtn.classList[servObj.currentScene <= servObj.startScene ? 'add' : 'remove']('disabled');
//         nextStepBtn && nextStepBtn.classList[servObj.currentScene >= servObj.finalScene ? 'add' : 'remove']('disabled');
        
//         scenes.jumpToScene(servObj.currentScene, 1)
//     });
// });