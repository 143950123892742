import * as THREE from "three";
import { animations } from "./animations.js";
import CustomShaderMaterial from "three-custom-shader-material/vanilla";

const materialList = [];

export const materials = {
	list: materialList,
	setMaterial: setMaterial,
	setAnimatedOverlay: setAnimatedOverlay,
};

export function setMaterial(object) {
	let existingMaterial;
	if (materialList.length > 0) {
		existingMaterial = materialList.find((mat) => mat.name === object.material.name);
	}

	if (existingMaterial) {
		object.material = existingMaterial.isUnique ? existingMaterial.clone() : existingMaterial;
		return;
	}

	if (object.material.type === "MeshBasicMaterial") {
		let oldMaterial = object.material.clone();
		object.material = customBasicMaterial();
		object.material.copy(oldMaterial);
	}

	materialList.push(object.material);
}

function customBasicMaterial() {
	const material = new CustomShaderMaterial({
		baseMaterial: THREE.MeshBasicMaterial,
		vertexShader: ``,
		fragmentShader: `
		uniform float uEmissiveIntensity;
        void main(){
			csm_DiffuseColor *= vec4(vec3(uEmissiveIntensity), 1);
        }
        `,
	});

	material.uniforms = { uEmissiveIntensity: { value: 1 } };
    Object.defineProperty(material, 'emissiveIntensity', {
        set: function(newValue) {
            this.uniforms.uEmissiveIntensity.value = newValue;
        },
        get: function() {
            return this.uniforms.uEmissiveIntensity.value;
        }
    });

	return material;
}

export function setAnimatedOverlay(obj, videoElemID, playbackRate, maxAppearOpacity) {
	const video = document.getElementById(videoElemID);
	video.play();
	if (playbackRate) video.playbackRate = playbackRate;

	const animatedMatParams = {
		map: new THREE.VideoTexture(video),
		color: new THREE.Color(0xffffff),
		blending: THREE.CustomBlending,
		blendDst: THREE.OneFactor,
		blendSrc: THREE.SrcColorFactor,
		transparent: true,
		opacity: 0,
	};
	animatedMatParams.map.flipY = false;
	const animatedMaterial = new THREE.MeshBasicMaterial(animatedMatParams);
	obj.material = animatedMaterial;

	animations.animatedMaterialAppear(
		animatedMaterial,
		video.duration / video.playbackRate,
		maxAppearOpacity
	);
}
